// Custom styles
.grayscale {
  filter: grayscale(100%);
  transition: filter 250ms ease-in;
}

.hover-color:hover {
  filter: none;
}

.logo-v2 {
  color: #212529;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: bold;
  user-select: none;
}

.logo-v2:hover {
  color: #252b33;
}

// Animation React logo
.spin {
  animation: spin 5s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

